.MuiDataGrid-renderingZone{
width:100%  !important;
}
.MuiDataGrid-row{
    width:100%  !important;

}
.MuiDataGrid-cell{
    color:#FFFFFF;
}

.MuiDataGrid-colCellTitle{
    color:#f40000;
}
.MuiDataGrid-footer{
    color:#FFFFFF
}